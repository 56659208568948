import React, { useContext } from 'react';

import TextField from '@mui/material/TextField';
import T from '@mui/material/Typography';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import PickListContext from 'pickList/pickListContext';
import YesNoAnswerList from './YesNoAnswerList';
import DateUpdated from './DateUpdated';
import useDateFormatter from 'common/useDateFormatter';
import { useTranslation } from 'react-i18next';

interface IProps {
	caseInfo: CaseDTO;
	olsDoNothing: YesNoRecordDTO[];
	olsInstructions: YesNoRecordDTO[];
	olsTestResult: OneLegStandTestDTO[];
	olsTestErrors: OneLegStandTestErrorDTO[];
	onNotesChanged: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
	submittedCase: boolean;
	subjectName: string;
}
const CaseDetailOLS = (props: IProps) => {
	const { caseInfo, olsTestErrors, olsTestResult, subjectName, submittedCase } = props;
	const { pickListItems } = useContext(PickListContext);
	const {formatTime, formatDateTime} = useDateFormatter(caseInfo.ianaTimeZone);	
	const { t } = useTranslation('ols');
	
	const olsErrorTypes = pickListItems.filter((item: PickListItemResponseDTO) => item.pickListTypeCode === 'ErrorType');
	
	interface IOLSDisplayDetail {
		id: number;
		errorTypeCode: string;
		degree: number;
		time: number;
		footdownCount: number;
	}
	const sortOLSresult = (a: IOLSDisplayDetail, b: IOLSDisplayDetail) => {
		return a.time! > b.time! ? 1 : -1;
	};
	const generateOLSDisplayItems = (errorTypeCode: string, testId?: string) => {
		const errorTypeItem = olsErrorTypes.find(o => o.code === errorTypeCode)!;
		const generatedList: IOLSDisplayDetail[] = [];
		olsTestErrors.filter((record: OneLegStandTestErrorDTO) => record.oneLegStandTestId === testId).forEach(testError => {
			if (testError.errorTypeId === errorTypeItem.id) {
				generatedList.push({
					id: testError.id,
					errorTypeCode,
					degree: testError.direction,
					time: testError.time,
					footdownCount: testError.footDownCount
				} as IOLSDisplayDetail);
			}
		});
		return generatedList.sort(sortOLSresult);
	};

	const getOLSActionCount = (targetArray: IOLSDisplayDetail[]) => {
		if (!targetArray) {
			return '-';
		}
		else if (targetArray.length <= 0) {
			return <T sx={{color:'text.disabled', fontSize:'0.875rem'}}>N/A</T>;
		}

		let count = '';
		targetArray.forEach((item, index) => {
			if (index > 0) {
				count += ', ';
			}
			count += item.footdownCount;
		})

		return <strong>{count}</strong>;
	}
	const renderOneOneLegStandTest = (ols: OneLegStandTestDTO, index: number) => {
		const footDownReport = generateOLSDisplayItems('FootDown', ols.id);
		const hoppingReport = generateOLSDisplayItems('Hopping', ols.id);
		const usesArmsReport = generateOLSDisplayItems('UsesArms', ols.id);
		const swayReport = generateOLSDisplayItems('Sway', ols.id);
		return (
			<>
				<T variant='h3'>
					{ols.resetDate ? `Incomplete Attempt ${index + 1}` : 'Completed Attempt'}
				</T>
				<T variant='h4'>Setup</T>
				<T variant='h6' component='h5'>Response to Instructions: </T>
				<T fontStyle='italic'>
					{t('demand1', 
					'Please stand with your heels and toes together, and your arms at your sides. (demonstrate) Do not do anything until I tell you to. Do you understand?'
					)}
				</T>
				<YesNoAnswerList answers={props.olsDoNothing} testId={ols.id} subjectName={subjectName} timeFormatter={formatTime} />
				<T fontStyle='italic'>
					{t('demand2',
					'When I tell you to, raise the leg of your choice approximately six inches off the ground, with your raised foot parallel to the ground. Keep both your legs straight. Hold that position while you count out loud in the following manner: “1000 & 1, 1000 & 2, 1000 & 3,” until I tell you stop (time for 30 seconds). \n\nWatch your raised foot while you are counting, and keep your arms at your sides.(demonstrate) Do you understand?'
					)}
				</T>
				<T fontStyle='italic'>
					Watch your raised foot while you are counting, and keep your arms at your sides. (demonstrate). Do you understand?
				</T>
				<YesNoAnswerList answers={props.olsInstructions} testId={ols.id} subjectName={subjectName} timeFormatter={formatTime} />
				<T sx={{mb:4}}>
					<strong>Additional Notes: </strong>
					{ols.instructionNotes ? ols.instructionNotes : <T fontStyle='italic'>No additional notes added.</T>}
				</T>
				<T variant='h4'>Test</T>
				<T variant='h6' component='h5'>Results: </T>
				<ul>
					{!ols.resetDate ?
						<>
							<li>
								<strong>{subjectName}</strong> was balancing on their <strong>{ols.footChoice ? 'right' : 'left'} leg</strong>.
							</li>
							{ols.footwear && 
								<li>
									<strong>{subjectName}</strong> was wearing <strong>{ols.footwear}</strong> for footwear.
								</li>
							}
						</>
						: null
					}
				</ul>
				<TableContainer>
					<Table>
						<TableHead sx={{ '& .MuiTableCell-head': {fontWeight: 700, borderBottom: 2, textTransform: 'uppercase', paddingBottom: 1} }}>
							<TableRow>
								<TableCell>Action</TableCell>
								<TableCell sx={{textAlign: 'right'}}>Times Observed</TableCell>
								<TableCell sx={{textAlign: 'right'}}>Subject Count</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>Puts Foot Down</TableCell>
								<TableCell sx={[{textAlign: 'right'}, footDownReport.length > 0 && {fontWeight:'bold'}]}>
									{footDownReport.length}
								</TableCell>
								<TableCell sx={{textAlign: 'right'}}>{getOLSActionCount(footDownReport)}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Arms for Balance</TableCell>
								<TableCell sx={[{textAlign: 'right'}, usesArmsReport.length > 0 && {fontWeight: 'bold'}]}>
									{usesArmsReport.length}
								</TableCell>
								<TableCellNA />
							</TableRow>
							<TableRow>
								<TableCell>Swaying</TableCell>
								<TableCell sx={[{textAlign: 'right'}, swayReport.length > 0 && {fontWeight: 'bold'}]}>
									{swayReport.length}
								</TableCell>
								<TableCellNA />
							</TableRow>
							<TableRow>
								<TableCell>Hop</TableCell>
								<TableCell sx={[{textAlign: 'right'}, hoppingReport.length > 0 && {fontWeight:'bold'}]}>
									{hoppingReport.length}
								</TableCell>
								<TableCellNA />
							</TableRow>
							<TableRow>
								<TableCell>Final Subject Count</TableCell>
								<TableCellNA />
								<TableCell sx={[{textAlign: 'right'}, ols.count! > 0 && {fontWeight: 'bold'}]}>
									{ols.count}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>				
				<T sx={{mb:4, py:4}}>
					<strong>Additional Notes: </strong>
					{ols.testNotes ? ols.testNotes : <T fontStyle='italic'>No additional notes added.</T>}
				</T>
				{ols.resetDate ? (
					<>
						<T variant='h4'>Restarted: </T>
						<T sx={{mb:3, pb:2}}>
							The test was restarted at
							<strong> {formatDateTime(ols.resetDate)}</strong>
						</T>
						<T>
							<strong>Restart Notes: </strong>
							{ols.resetNote || <T fontStyle='italic'>No restart notes added.</T>}
						</T>
					</>
				) : (
					<>
						{ols.stoppedDateTime && ols.stoppedNotes ? (
							<>
								<T variant='h4'>Stopped</T>
								<T sx={{mb:3, pb:2}}>
									The test was stopped at
									<strong> {formatDateTime(ols.stoppedDateTime)}</strong>
								</T>
								<T sx={{mb:4}}>
									<strong>Stop Notes: </strong>
									{ols.stoppedNotes || <T fontStyle='italic'>No stop notes added.</T>}
								</T>
							</>
						) : (
							<>
								{ols.stoppedDateTime && (
									<>
										<T variant='h4'>Completed</T>
										<T sx={{mb:3, pb:2}}>
											The test was completed at
											<strong> {formatDateTime(ols.stoppedDateTime)}</strong>
										</T>
										<T sx={{mb:4}}>
											<strong>Stop Notes: </strong>
											{ols.stoppedNotes || <T fontStyle='italic'>No stop notes added.</T>}
										</T>
									</>)
								}
							</>
						)}
						<T variant='h4'>Decision Points: {ols.decisionPoint}/4</T>
					</>
				)}
			</>
		);
	}


	return (
		<>
			<T variant='h2'>6. One Leg Stand</T>
			{olsTestResult.map((ols, index) => (
				<div className="pt-3" key={index}>
					{renderOneOneLegStandTest(ols, index)}
				</div>
			))}
			<T variant='h3'>Other indicia: </T>
			{submittedCase ?
				(
					<TextField
						multiline
						rows={9}
						fullWidth
						name="olsTestAdditionalNotes"
						label="Post-Test Comments: "
						value={caseInfo.olsTestAdditionalNotes}
						onChange={(evt: any) => props.onNotesChanged(evt)}
					/>
				) :
				(
					<p>
						<strong>Post-Test Comments: </strong>
						{caseInfo.olsTestAdditionalNotes ? 
							<>
								{caseInfo.olsTestAdditionalNotes}
								<DateUpdated dateUpdated={caseInfo.dateUpdated!} formatter={formatDateTime} />
							</>
						:
							<T fontStyle='italic'>No post-test comments added.</T>
						}
					</p>
				)
			}
		</>
	);
}
export default CaseDetailOLS;

const TableCellNA = () => {
	return (
		<TableCell sx={{color:'text.disabled', textAlign:'right'}}>N/A</TableCell>
	);
};