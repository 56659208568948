import { List, ListItem, SxProps, Theme } from '@mui/material';
import T from '@mui/material/Typography';
import useDateFormatter from 'common/useDateFormatter';
import { isDate } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
	caseInfo: CaseDTO;
	caseTextOnChange: (evt: any) => void;
	suspectUnderstandsSfst: YesNoRecordDTO[];
	subjectName: string;
	sfstSetup: SFSTSetupDTO;
}

const CaseSFSTSetup = ( props: IProps ) => {

	const { caseInfo,  subjectName, sfstSetup } = props;
	const {formatTime} = useDateFormatter(caseInfo.ianaTimeZone);
	
	const { t } = useTranslation('sfstSetup');

	const generateUnderstandSFSTAnswerRows = () => {
		return (
			<React.Fragment>
				<AnswerList>
					{props.suspectUnderstandsSfst.map((record => {
						return (
							<AnswerListItem key={record.id}>
								<strong>{subjectName}</strong> &nbsp;answered {record.yes ? 'Yes' : 'No'} at {formatTime(record.date!)}.
							</AnswerListItem>
						);
					}))}
				</AnswerList>
			</React.Fragment>
		);
	};

	return (
		<>
			<T variant='h2'>2. SFST Setup</T>
			<T variant='h6' component='h3'>Response to Instructions:</T>
			<T sx={{mb: 3, pb: 2}}>
				The SFST demand was read by <strong>{caseInfo.officerName}</strong> <strong>{isDateValue(sfstSetup.demandRead) ? formatTime(sfstSetup.demandRead) : 'N/A'}</strong>
			</T>
			<T fontStyle='italic'>
				{t('demand', 
					'I demand that you perform mandatory physical coordination tests to determine if your ability to operate a conveyance is impaired by alcohol, a drug, or a combination of both, and that you accompany me now for this purpose. Do you understand?'
				)}
			</T>
			{generateUnderstandSFSTAnswerRows()}
			<AnswerList>
				<AnswerListItem>
					<strong>{subjectName}</strong> &nbsp;indicated they {sfstSetup.earAndBalance ? 'did' : 'did not'} have problems with their ears or balance.					
				</AnswerListItem>
				{sfstSetup.earAndBalance &&
				<AnswerListItem sx={{pl: 5}}>
					{sfstSetup.earAndBalanceExplanation || <T fontStyle='italic'>No explanation given.</T>}
				</AnswerListItem>
				}
				<AnswerListItem>
					<strong>{subjectName}</strong> &nbsp;indicated they {sfstSetup.medicalPhysicalBackLegs ? 'did' : 'did not'} have back or leg problems.					
				</AnswerListItem>
				{sfstSetup.medicalPhysicalBackLegs && 
				<AnswerListItem sx={{pl: 5}}>
					{sfstSetup.medicalPhysicalBackLegsExplanation || <T fontStyle='italic'>No explanation given.</T>}
				</AnswerListItem>
				}
			</AnswerList>
			<T>
				<strong>Additional Notes:</strong><br />
				{sfstSetup.additionalNotes ? sfstSetup.additionalNotes : <T fontStyle='italic'>No additional notes added.</T>}
			</T>
		</>
	);
};

export default CaseSFSTSetup;
interface ListProps {    
    children?: React.ReactNode;
    /**
    * The system prop that allows defining system overrides as well as additional CSS styles.
    */
    sx?: SxProps<Theme>;
  }
const AnswerList = (props: ListProps) => {
	return (
		<List sx={{pt: 0, pb: 2, ...props.sx}}>
			{props.children}
		</List>
	);
}
const AnswerListItem = (props: ListProps) => {
	return (
		<ListItem sx={{my: 0, py: 0, ...props.sx}}>
			{props.children}
		</ListItem>
	);
}

const isDateValue = (date: string | undefined): date is string => {
	return date !== undefined && date !== null && date !== '' && date.startsWith('0001-01-01') === false;
};
