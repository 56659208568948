import React, { useContext, useEffect } from 'react';
import AuthContext from 'auth/AuthContext';
import i18n from 'common/i18n';

const LanguageLoader = () => {
	const {tenantSettings} = useContext(AuthContext);
	
	useEffect(() => {
		if(tenantSettings?.languageFile) {
			const lng = JSON.parse(tenantSettings.languageFile!);

			//for each key in the lng object, add it to the i18n resource bundle
			for (const key in lng) {
				if (Object.prototype.hasOwnProperty.call(lng, key)) {
					i18n.addResourceBundle('en', key, lng[key], true, true);
				}
			}
			
			i18n.addResourceBundle('en', 'translation', lng.translation, true, true);
		}

	}, [tenantSettings?.languageFile]);

	return (
		<></>
	);
};
export default LanguageLoader;
